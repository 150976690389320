import CreateGroupLayout from "../../layouts/grid/CreateGroup";
import Loading from "../Loading";

const CreateGroupSkeleton = () => {
  return (
    <CreateGroupLayout>
      <Loading />
    </CreateGroupLayout>
  )
}

export default CreateGroupSkeleton;