import { Center, Heading } from "@chakra-ui/react";

const Dashboard: React.FC = () => {
  return (
    <>
      <Center w="100%" h="100%">
        <Heading size="sm">
          Select a group from the left or create a new one
        </Heading>
      </Center>
    </>
  );
};

export default Dashboard;
